import React from 'react';
import FirebaseProvider from './firebase/provider'

const rootWrapper = ({ element }) => (
    <FirebaseProvider>
        {element}
    </FirebaseProvider>
);

export default rootWrapper;
