import { useContext, createContext, useEffect } from "react"

const FirebaseContext = createContext(null)

export function useFirebase(fn, dep = []) {
    const firebase = useContext(FirebaseContext)
    useEffect(() => {
        if (!firebase) {
            return
        }
        return fn(firebase)
    }, [firebase, ...dep]) // eslint-disable-line react-hooks/exhaustive-deps
}

export default FirebaseContext