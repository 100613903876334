import React, { useEffect, useState } from "react"
import FirebaseContext from "./context"

function FirebaseProvider({ children }) {
    const [firebase, setFirebase] = useState(null)

    useEffect(() => {
        if (!firebase && typeof window !== "undefined") {
            const app = import("firebase/app")
            const auth = import("firebase/auth")
            // const database = import("firebase/database")
            // const firestore = import("firebase/firestore")
            const storage = import("firebase/storage")
            // const messaging = import("firebase/messaging")
            // const functions = import("firebase/functions")
            // const performance = import("firebase/performance")
            // const analytics = import("firebase/analytics")

            Promise.all([
                app,
                auth,
                // database,
                // firestore,
                storage,
                // messaging,
                // functions,
                // performance,
                // analytics,
            ]).then(values => {
                const firebaseInstance = values[0].default
                firebaseInstance.initializeApp({
                    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
                    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
                    databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
                    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
                    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
                    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
                    appId: process.env.GATSBY_FIREBASE_APP_ID,
                    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
                })
                setFirebase(firebaseInstance)
            })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <FirebaseContext.Provider value={firebase}>
            {children}
        </FirebaseContext.Provider>
    )
}

export default FirebaseProvider